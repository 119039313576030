.plans-container{
    padding: 0 2rem;
    margin-top: -4rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans-header{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    color: White;
    text-transform: uppercase;
     justify-content: center;
    gap: 1rem;
}
.plans{
   display: flex;
   gap: 3rem;
   align-items: center;
   justify-content: center;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    scale: 1.1;
}
.plan:nth-child(2)>svg{
    fill: white;
}
.plan>svg{
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}
.plan >:nth-child(2){
    font-weight: bold;
    font-size: 1rem;
}
.plan >:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.plan >:nth-child(5){
    /* font-weight: bold; */
    font-size: .8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature img{
    width: 1rem;
}

.pblur1{
    width: 32rem;
    height: 23rem;
    left: 0rem;
    top: 6rem;
}

.pblur2{
    width: 32rem;
    height: 23rem;
    right: 0rem;
    top: 10rem;
}
@media screen  and (max-width: 768px){
    .plans-header{
        font-size: x-large;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
    
}