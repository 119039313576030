.program{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}
.program-header{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    color: White;
    text-transform: uppercase;
     justify-content: center;
     gap: 1rem;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.program-category{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: gray;
    color: white;
    justify-content: space-between;
    gap: 1rem;
}
.program-category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: white;
}
.program-category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
}
.program-category>:nth-child(1){
    font-size: .9rem;
    line-height: 25px;
}
.join{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.join img{
    width: 1rem ;
}
.program-category:hover{
    background: var(--planCard);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .program-header{
        font-size: x-large;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }
    .program-categories{
        flex-direction: column;
    }
    
}