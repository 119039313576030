.join{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
}
.left-j{
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: white;

}
.right-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  
}
.email-container{
    display: flex;
    gap: 3rem;
    background: gray;
    padding: 1rem 2rem;

}
.email-container>input{
   background: transparent;
   border: none;
   outline: none;
   color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-join{
    background: var(--orange);
    color: white;
}
.join{
    flex-direction: column;
}
.left-j{
    font-size: xx-large;
}
.right-j{
    position: relative;
}
.email-container{
    position: relative;
    padding: .5rem 1rem;
}