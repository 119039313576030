
.Footer-container{
    position: relative;
    background: var(--planCard);
}
/* .Footer-container>hr{
    border: 1px solid var(--gray);
} */
.Footer{
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10rem;
    gap: 2rem;
}
.social{
    display: flex;
    gap: 4rem;
}
.social>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
    
}